 var swiper2 = new Swiper(".mySwiper2", {
    effect: "fade",
    autoplay: {
        delay: 4000,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
  });

  var swiperTestimony = new Swiper(".testimonySwiper", {
    autoHeight: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
});
// Function to animate text with GSAP
function animateText(element) {
    gsap.fromTo(
      element,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, duration: 0.5, ease: "expo.out",  delay: .5, stagger: 0.2 }
    );
  }
  function animateTextUpper(element) {
    gsap.fromTo(
      element,
      { opacity: 0, y: -60 },
      { opacity: 1, y: 0, duration: 1, ease: "expo.out"  }
    );
  }
//   function animateImg(element) {
//     gsap.fromTo(
//       element,
//       {  xPercent: 100},
//       {  xPercent: 0, duration: 1, ease: "none"  }
//     );
//   }

  // Add a listener to the Swiper slideChange event
  swiper2.on('slideChange', function () {
    // Get the currently active slide
    var activeSlide = swiper2.slides[swiper2.activeIndex];

    // Find the text element within the slide (adjust the selector as needed)
    var textElement = activeSlide.querySelectorAll('.animated-text');
    var textElement2 = activeSlide.querySelector('.animated-textUpper');
    var imgElement = activeSlide.querySelector('.animated-img');

    // Animate the text with GSAP
    animateText(textElement);
    animateTextUpper(textElement2);
    // animateImg(imgElement);
  });

